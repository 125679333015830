@import 'less/variables.less';
@import 'styles/colors.less';

.carousel {
  height: 435px;
  margin-top: 50px;
  max-width: 1200px;
  position: relative;

  @media (max-width: (@screen-sm - 1)) {
    height: auto;
    margin-top: 0;
  }

  .arrows {

    .arrow-nav {
      position: absolute;
      top: 20%;

      @media (max-width: (@screen-sm - 1)) {
        display: none;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }

      &.left-nav {
        left: 0;
      }

      &.right-nav {
        right: 0;
      }
    }
  }

  .carousel-items-wrapper {
    overflow: hidden;

    .carousel-items {
      display: flex;
      transition: transform ease-out 0.5s;

      .carousel-item {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        width: 100%;

        @media (max-width: (@screen-sm - 1)) {
          flex-direction: column-reverse;
        }


        .left-side {
          @media (max-width: (@screen-sm - 1)) {
            margin: 50px auto 0;
            text-align: center;
          }

          a {
            display: inline-block;

            img {
              @media (max-width: (@screen-md - 1)) {
                margin: 0 auto;
                max-width: 345px;
                width: 100%;
              }
            }
          }

        }

        .right-side {
          margin-left: 40px;

          @media (max-width: (@screen-sm - 1)) {
            margin-left: 0;
            text-align: center;
          }

          img {
            display: block;

            @media (max-width: (@screen-sm - 1)) {
              margin: 0 auto;
            }
          }

          hr {
            border: 2px solid @color-gray-primary;
            box-sizing: border-box;
            display: inline-block;
            height: 3px;
            margin-top: 40px;
            width: 59px;

            @media (max-width: (@screen-sm - 1)) {
              margin: 20px 0 0;
              width: 74px;
            }
          }

          a {

            &:hover {
              text-decoration: none;
            }

            h3 {
              color: @color-blue-sapphire;

              @media (max-width: (@screen-sm - 1)) {
                margin-bottom: 30px;
              }
            }

            div {
              color: @color-gray-primary;
              max-width: 500px;

              @media (max-width: (@screen-sm - 1)) {
                margin: 0 auto;
                max-width: 90%;

              }
            }
          }
        }
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    div {
      background-color: #DBDBDB;
      border-radius: 50px;
      border: 3px solid white;
      cursor: pointer;
      height: 14px;
      margin: 0 10px;
      width: 14px;

      &:hover {
        background-color: #455B9D;
        border: 3px solid #455B9D;
      }

      &.active {
        background-color: #00C4C9;
        border: 3px solid #00C4C9;
      }
    }
  }
}
