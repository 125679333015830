/** Primary Colors **/
/** Secondary Colors **/
/** Grays **/
/** Games Colors **/
/** Other Colors **/
/** Alert Boxes **/
/** Content Type Colors **/
/** Top Nav and Footer **/
/********* Media Query break points *********/
/** proficiency level colors **/
/******* old Colors *****/
.carousel {
  height: 435px;
  margin-top: 50px;
  max-width: 1200px;
  position: relative;
}
@media (max-width: 767px) {
  .carousel {
    height: auto;
    margin-top: 0;
  }
}
.carousel .arrows .arrow-nav {
  position: absolute;
  top: 20%;
}
@media (max-width: 767px) {
  .carousel .arrows .arrow-nav {
    display: none;
  }
}
.carousel .arrows .arrow-nav:hover {
  background-color: #ffffff;
}
.carousel .arrows .arrow-nav.left-nav {
  left: 0;
}
.carousel .arrows .arrow-nav.right-nav {
  right: 0;
}
.carousel .carousel-items-wrapper {
  overflow: hidden;
}
.carousel .carousel-items-wrapper .carousel-items {
  display: flex;
  transition: transform ease-out 0.5s;
}
.carousel .carousel-items-wrapper .carousel-items .carousel-item {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .carousel .carousel-items-wrapper .carousel-items .carousel-item {
    flex-direction: column-reverse;
  }
}
@media (max-width: 767px) {
  .carousel .carousel-items-wrapper .carousel-items .carousel-item .left-side {
    margin: 50px auto 0;
    text-align: center;
  }
}
.carousel .carousel-items-wrapper .carousel-items .carousel-item .left-side a {
  display: inline-block;
}
@media (max-width: 999px) {
  .carousel .carousel-items-wrapper .carousel-items .carousel-item .left-side a img {
    margin: 0 auto;
    max-width: 345px;
    width: 100%;
  }
}
.carousel .carousel-items-wrapper .carousel-items .carousel-item .right-side {
  margin-left: 40px;
}
@media (max-width: 767px) {
  .carousel .carousel-items-wrapper .carousel-items .carousel-item .right-side {
    margin-left: 0;
    text-align: center;
  }
}
.carousel .carousel-items-wrapper .carousel-items .carousel-item .right-side img {
  display: block;
}
@media (max-width: 767px) {
  .carousel .carousel-items-wrapper .carousel-items .carousel-item .right-side img {
    margin: 0 auto;
  }
}
.carousel .carousel-items-wrapper .carousel-items .carousel-item .right-side hr {
  border: 2px solid #464646;
  box-sizing: border-box;
  display: inline-block;
  height: 3px;
  margin-top: 40px;
  width: 59px;
}
@media (max-width: 767px) {
  .carousel .carousel-items-wrapper .carousel-items .carousel-item .right-side hr {
    margin: 20px 0 0;
    width: 74px;
  }
}
.carousel .carousel-items-wrapper .carousel-items .carousel-item .right-side a:hover {
  text-decoration: none;
}
.carousel .carousel-items-wrapper .carousel-items .carousel-item .right-side a h3 {
  color: #5672c4;
}
@media (max-width: 767px) {
  .carousel .carousel-items-wrapper .carousel-items .carousel-item .right-side a h3 {
    margin-bottom: 30px;
  }
}
.carousel .carousel-items-wrapper .carousel-items .carousel-item .right-side a div {
  color: #464646;
  max-width: 500px;
}
@media (max-width: 767px) {
  .carousel .carousel-items-wrapper .carousel-items .carousel-item .right-side a div {
    margin: 0 auto;
    max-width: 90%;
  }
}
.carousel .navigation {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.carousel .navigation div {
  background-color: #DBDBDB;
  border-radius: 50px;
  border: 3px solid white;
  cursor: pointer;
  height: 14px;
  margin: 0 10px;
  width: 14px;
}
.carousel .navigation div:hover {
  background-color: #455B9D;
  border: 3px solid #455B9D;
}
.carousel .navigation div.active {
  background-color: #00C4C9;
  border: 3px solid #00C4C9;
}
